/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

	// jQuery EXTENSIONS
	jQuery.fn.center = function() {
		var $this = $(this);
		var offset = $this.offset();
		var width = $this.width();
		var height = $this.height();

		var centerX = offset.left + width / 2;
		var centerY = offset.top + height / 2;

		return {
			x: centerX,
			y: centerY
		};
	};

  function getScrollbarWidth (callback) {

    // Select the body element
    var $body = $("body");

    // Add the html we need
    $body.append("<div id=\"outsideScroll\"><div id=\"theScroll\"> " + 
      "<div id=\"insideScroll\"></div></div></div>");

    // Select the elements we just added
    var $outsideScroll = $("#outsideScroll");
    var $insideScroll = $("#insideScroll");
    var $theScroll = $("#theScroll");

    // Apply css rules
    $outsideScroll.css("position", "absolute");
    $outsideScroll.css("opacity", "0");
    $outsideScroll.css("visibility", "hidden");
    $theScroll.css("overflow", "scroll");

    // Calculate the difference between the two divs
    // This should result on the width of the scrollbar
    var scrollbarWidth = $outsideScroll.width() - $insideScroll.width();

    // Delete the html we inserted into the page
    $outsideScroll.remove();

    // Pass the scrollback width to the callback
    callback(scrollbarWidth);
  }

  function setScrollbarOffsetRules(scrollbarWidth) {

    jQuery("body").append("<style>" +
      "body.disable-scroll .toggle-holder { right: " + scrollbarWidth + "px; } " + 
      "body.disable-scroll { padding-right: " + scrollbarWidth + "px ; } " +
      "</style>"
    );
  }
  
  // Adds leading zeros to numbers
  function pad(num, size) {
        var s = num+"";
        while (s.length < size)  { 
          s = "0" + s;
        }
        return s;
  }

  // Format ISO date
  function formatISODate(date) {
    var day = pad(date.getDate(), 2);
    var month = pad(date.getMonth() + 1, 2);
    var year = date.getFullYear();
    var formattedDate = (year) + "-" + (month) + "-" + (day);
    return formattedDate;
  }
  
  // Parses data out of user agent string
  var md = new MobileDetect(window.navigator.userAgent);

  // Normalize Datepickers ---------------------------------------------------
   
  // For all elements with class datepicker, 
  // we want to use the bootstrap-datepicker tooltip on desktop
  // and we want to set the input type to "date" on mobile.
 
  // HELPER FUNCTIONS
  // MAIN FUNCTIONALITY
  
  /**
   * Traverses through DOM to find elements with class datepicker.
   * Applies some changes and event bindings to make our date picking
   * experiences useful, consistent, and productive accross all
   * screen sizes and device types.
   */
  function makeDatePickersNice() {

    // Traverse through the document and gather all elements 
    // with class datepicker
    var $datepickers = jQuery('.datepicker');

    // Determine if the current device is a mobile device
    if(md.tablet() || md.phone()) {

      // The current device is a mobile device

      // Iterate through each datepicker that was found previosly
      $datepickers.each(function (index, pickerElement) {

        // From the current html element make a jQuery object
        var $picker = jQuery(pickerElement);

        // Change the attribute type to date to use the browsers date controls
        $picker.attr('type','date');

        pickerElement.defaultValue = formatISODate(new Date());
      });
    }
    else {

      // The current device is a desktop device

      // Use the standard bootstrap-datepicker method to 
      // add a datepicker tooltip

      var datepickerOptions = {
        format: 'mm/dd/yyyy',
        startDate: new Date()
      };

      $datepickers
        .datepicker(datepickerOptions);
    }
  }

	// Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // Get scrollbar width here--------------------------------------------
        getScrollbarWidth(function (width) {
          // Set some styles according to scrollbar widths
          setScrollbarOffsetRules(width);
        });
        
        // Datepickers --------------------------------------------------------

        // We are calling this function early here in the common init because 
        // it adds event bindings to forms that we want to be called as early
        // as possible.
        // 
        // Javascript runs event callbacks in the order in which they are 
        // bound.
        // makeDatePickersNice();

        // Thank You Page For Contact Form-------------------------------------
				document.addEventListener( 'wpcf7mailsent', function() {
					window.location = '/thank-you';
				}, false );
      },
      finalize: function() {

				// Header Toggles
				var caPrimaryNav = jQuery('nav.nav-toggle');
				var toggleMenuButton = jQuery('.toggle-menu');
				var $body = jQuery('body');

				// Menu
				toggleMenuButton.click(function() {
					TweenMax.to(window, 0.125, {  ease: Power2.easeIn, scrollTo:{y:0, x:0}, onComplete: function() {

            console.log(arguments);

            caPrimaryNav.toggleClass('ca-banner-menu-hidden');
            toggleMenuButton.toggleClass('toggle-menu-active');

            // We want a nice transition between the page being scrollable and
            // the menu being scrollable
            setTimeout(function () {
              // We want to toggle scroll on the menu mid transition
              caPrimaryNav.toggleClass("enable-scroll");

              // And at the toggle scroll off the menu
              $body.toggleClass("disable-scroll");

              // This mid flight, sycnchronized scroll swap looks somewhat decent on windows

              // We have tested various delays and it seems that the midpoint of
              // the transition is the best way to go.
              //
              // Since we have set the duration of the animation 
              // in SCSS variable $menu-transition-length to 0.5s,
              // We set the delay here to 0.25s
            }, 250);         
          }});
				});
				// JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        
        // Fancy Down Arrow ---------------------------------------------------
        var showFancyDownArrow = false;
        if(showFancyDownArrow) {
          
          var $homeArrow = $(".home-down-arrow");

          $homeArrow.click(function () {
					  TweenMax.to(window, 1, {scrollTo:{y:500, x:0}, ease:Power2.easeInOut});
				  });

          $(".main-banner").on("mousemove", function (e) {
            var arrowCenter = $homeArrow.center();
            var distance = {
              x: arrowCenter.x - e.pageX,
              y: arrowCenter.y - e.pageY
            };
            var rotation = {
              x: 45 * (distance.x / 700),
              y: 35 * ((distance.y - 20 ) / 700)
            };

            // noinspection JSSuspiciousNameCombination
            TweenMax.to(
              ".home-down-arrow-spin",
              3,
              {
                rotationX: rotation.y,
                rotationY: rotation.x,
                ease: Power1.easenone
              }
            );
          });
        }
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
